document.querySelector('.navbar-toggler').addEventListener('click', function () {
    // Toggle all dropdown menus when the navbar is expanded
    const dropdowns = document.querySelectorAll('.nav-item.dropdown .dropdown-toggle');
    dropdowns.forEach(dropdown => {
        const parent = dropdown.parentElement;
        if (!parent.classList.contains('show')) {
            parent.classList.add('show');
            dropdown.setAttribute('aria-expanded', 'true');
            parent.querySelector('.dropdown-menu').classList.add('show');
        }
    });
});

// Close dropdowns when navbar is collapsed
document.querySelector('.navbar-toggler').addEventListener('click', function () {
    const navbarContent = document.querySelector('#navbarContent');
    navbarContent.addEventListener('hidden.bs.collapse', function () {
        const dropdowns = document.querySelectorAll('.nav-item.dropdown');
        dropdowns.forEach(dropdown => {
            dropdown.classList.remove('show');
            dropdown.querySelector('.dropdown-menu').classList.remove('show');
            dropdown.querySelector('.dropdown-toggle').setAttribute('aria-expanded', 'false');
        });
    });
});