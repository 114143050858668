document.addEventListener("DOMContentLoaded", function() {

    // Get the user's timezone from their browser
    let userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Get the user's locale from their browser
    let locale = navigator.language || navigator.userLanguage;
    // Get user browser datetime
    let userDateTime = new Date().toISOString();

    // Store the timezone and locale in a cookie or send it to the server
    document.cookie = "timezone=" + userTimezone + "; SameSite=None; Secure";
    document.cookie = "locale=" + locale + "; SameSite=None; Secure";
    document.cookie = "userDateTime" + userDateTime + "; SameSite=None; Secure";

});