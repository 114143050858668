import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
document.addEventListener("DOMContentLoaded", function() {

    CookieConsent.run({

        categories: {
            necessary: {
                enabled: true,  // this category is enabled by default
                readOnly: true  // this category cannot be disabled
            },
            analytics: {
                autoClear: {
                    cookies: [
                        {
                            name: /^_ga/,   // regex: match all cookies starting with '_ga'
                        },
                        {
                            name: '_gid',   // string: exact cookie name
                        }
                    ]
                },

                // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
                services: {
                    ga: {
                        label: 'Google Analytics',
                        onAccept: () => {},
                        onReject: () => {}
                    },
                    youtube: {
                        label: 'Youtube Embed',
                        onAccept: () => {},
                        onReject: () => {}
                    },
                }
            },
            ads: {}
        },

        language: {
            default: 'en',
            translations: {
                en: {
                    consentModal: {
                        title: 'Cookies',
                        description: 'We use cookies. You can turn them off in the <button type="button" class="cc-setting-link" data-cc="show-preferencesModal" aria-haspopup="dialog">settings</button>.',
                        acceptAllBtn: 'OK',
                        acceptNecessaryBtn: '',
                        showPreferencesBtn: ''
                    },
                    preferencesModal: {
                        title: 'Cookies settings',
                        acceptAllBtn: 'Accept all',
                        acceptNecessaryBtn: 'Reject all',
                        savePreferencesBtn: 'Accept current selection',
                        closeIconLabel: 'Close modal',
                        sections: [
                            {
                                title: ' ',
                                description: 'For the functioning of the Askhoroscope.com website, we use cookies and similar technologies that store our pages into your device via a browser or read from it. Cookies can record certain specific information from your visits to websites. Based on their purposes, we can divide cookies into functional (necessary), analytical, and marketing cookies. We respect your right to privacy, therefore you can decide not to accept certain cookies. By clicking on <strong>"Accept selected"</strong> without selected preferences, all optional cookies will be blocked. <br/><br/>You can find more information on the page <a href="/privacy-policy/">Protection of personal data</a>.'
                            },
                            {
                                title: 'Functional cookies',
                                description: 'Necessary cookies are those that are essential for the proper functioning of the website, its security, or the provision of its essential functions and services. Therefore, you cannot reject these cookies, and we do not need your consent to process them.',

                                //this field will generate a toggle linked to the 'necessary' category
                                linkedCategory: 'necessary'
                            },
                            {
                                title: 'Analytical cookies',
                                description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                                linkedCategory: 'analytics',
                                cookieTable: {
                                    caption: 'Cookie table',
                                    headers: {
                                        name: 'Cookie',
                                        domain: 'Domain',
                                        desc: 'Description'
                                    },
                                    body: [
                                        {
                                            name: '_ga',
                                            domain: location.hostname,
                                            desc: '-'
                                        },
                                        {
                                            name: '_gid',
                                            domain: location.hostname,
                                            desc: '-'
                                        }
                                    ]
                                }
                            },

                        ]
                    }
                }
            }
        }
    });



});